import {
  format,
  differenceInHours,
} from 'date-fns';
import moment from 'moment';

import { DATE_FORMAT_DEFAULT } from '../config/locale';

/**
 * Convert given date to UTC
 * @param date Plain JS date object or Date() acceptable string
 * @return {(Date | null)} Returns a UTC date for valid inputs, otherwise null
 */
export function toUTC(dateParam) {
  let date = dateParam;
  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  }

  if (date instanceof Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }
  return null;
}

export function formatUTC(date, pattern) {
  return format(+(toUTC(new Date(date))), pattern);
}

export function differenceInDays(toDate, fromDate) {
  const startUTC = toUTC(fromDate);
  const endUTC = toUTC(toDate);
  const hours = differenceInHours(endUTC, startUTC);
  return Math.floor(hours / 24);
}

/**
 * Description
 * @param {MomentDate} date
 * @param {[ startDate: string, endDate: string ]} range
 * @param {{
 *  includeStart: boolean,
 *  includeEnd: boolean,
 * }} options
 * @returns {any}
 */
export const dateIsInRange = (date, range, options = {
  includeStart: true,
  includeEnd: true,
}) => {
  if (!range) return false;
  if (!range[0] || !range[1]) return false;
  const [startDate, endDate] = range;

  const isStart = date.isSame(startDate);
  const isEnd = date.isSame(endDate);
  const isSameDate = isStart || isEnd;
  const isAfterStart = date.isAfter(startDate);
  const isBeforeEnd = date.isBefore(endDate);

  if (!options.includeStart && isStart) return false;
  if (!options.includeEnd && isEnd) return false;
  return isSameDate || (isAfterStart && isBeforeEnd);
};

/**
 * Works out how many days offset we are for the 1st of the month
 * in the calendar. E.g. August 2024 is offset by 3 (as the 1st is
 * a Thursday)
 * As the calendar uses Monday as index 0 we use isoWeekday here
 * @param {string} firstOfTheMonth
 * @returns {number}
 */
export const calculateCalendarMonthOffset = (firstOfTheMonth) => {
  const momentDate = moment(firstOfTheMonth, DATE_FORMAT_DEFAULT);
  const dayOfWeekIndex = momentDate.isoWeekday();
  return dayOfWeekIndex - 1;
};

/**
 * Returns the start and end dates for a month given a date in that month
 * @param {string} dateInMonth
 * @returns {{
 *  start: string,
 *  end: string,
 * }}
 */
export const getStartAndEndOfMonth = (dateInMonth) => {
  const momentDate = moment(dateInMonth, DATE_FORMAT_DEFAULT);
  return {
    start: momentDate.startOf('month').format(DATE_FORMAT_DEFAULT),
    end: momentDate.endOf('month').format(DATE_FORMAT_DEFAULT),
  };
};

export const getMonthOffsetFromDateInMonth = (dateInMonth) => {
  const { start } = getStartAndEndOfMonth(dateInMonth);
  return calculateCalendarMonthOffset(start);
};
