import { AMEND_RESTRICTIONS } from '../../config/amendRestrictions';
import svgCheck from '../../static/images/icons/Check.svg';
import EXTRA_TYPES from '../../config/extraTypes';
import theme from '../../styles/config/theme';
import { sortBookings } from '../../components/Basket/helpers';

export const AMEND_MODALS = {
  AMEND_CANCEL: 'AMEND_CANCEL',
  AMEND_CANCEL_BACK_TO_AMENDING: 'AMEND_CANCEL_BACK_TO_AMENDING',
  // outfits
  AMEND_OUTFIT: 'AMEND_OUTFIT',
  AMEND_OUTFIT_FORM: 'AMEND_OUTFIT_FORM',
  AMEND_OUTFIT_LOADING: 'AMEND_OUTFIT_LOADING',
  AMEND_OUTFIT_SUCCESS: 'AMEND_OUTFIT_SUCCESS',
  AMEND_OUTFIT_FAILURE: 'AMEND_OUTFIT_FAILURE',
  // party
  AMEND_PARTY: 'AMEND_PARTY',
  AMEND_PARTY_FORM: 'AMEND_PARTY_FORM',
  AMEND_PARTY_LEAD_FORM: 'AMEND_PARTY_LEAD_FORM',
  AMEND_PARTY_MEMBER_FORM: 'AMEND_PARTY_MEMBER_FORM',
  AMEND_PARTY_ADD_MEMBER_FORM: 'AMEND_PARTY_ADD_MEMBER_FORM',
  AMEND_PARTY_LOADING: 'AMEND_PARTY_LOADING',
  AMEND_PARTY_SUCCESS: 'AMEND_PARTY_SUCCESS',
  AMEND_PARTY_FAILURE: 'AMEND_PARTY_FAILURE',
  AMEND_PARTY_MAX_OCCUPANCY: 'AMEND_PARTY_MAX_OCCUPANCY',
  AMEND_PARTY_REMOVE_MEMBER: 'AMEND_PARTY_REMOVE_MEMBER',
  // contact
  AMEND_LEAD_EMAIL_FORM: 'AMEND_LEAD_EMAIL_FORM',
  AMEND_LEAD_MOBILE_FORM: 'AMEND_LEAD_MOBILE_FORM',
  // date
  AMEND_DATE_OVERLAP: 'AMEND_DATE_OVERLAP',
  // cancel
  AMEND_CANCEL_BOOKING: 'AMEND_CANCEL_BOOKING',
  AMEND_CANCEL_STAY: 'AMEND_CANCEL_STAY',
  AMEND_CANCEL_AMENDING: 'AMEND_CANCEL_AMENDING',
  AMEND_IN_PROGRESS: 'AMEND_IN_PROGRESS',
  AMEND_REMOVE_MEMBERSHIP: 'AMEND_REMOVE_MEMBERSHIP',
  // not allowed
  AMEND_NOT_ALLOWED: 'AMEND_NOT_ALLOWED',
};

export const AMEND_CANCEL_MODALS = [
  AMEND_MODALS.AMEND_CANCEL_BOOKING,
  AMEND_MODALS.AMEND_CANCEL_STAY,
];

export const AMEND_OUTFIT_MODALS = [
  AMEND_MODALS.AMEND_OUTFIT,
  AMEND_MODALS.AMEND_OUTFIT_FORM,
  AMEND_MODALS.AMEND_OUTFIT_LOADING,
  AMEND_MODALS.AMEND_OUTFIT_SUCCESS,
  AMEND_MODALS.AMEND_OUTFIT_FAILURE,
];

export const AMEND_PARTY_MODALS = [
  AMEND_MODALS.AMEND_PARTY,
  AMEND_MODALS.AMEND_PARTY_FORM,
  AMEND_MODALS.AMEND_PARTY_LEAD_FORM,
  AMEND_MODALS.AMEND_PARTY_MEMBER_FORM,
  AMEND_MODALS.AMEND_PARTY_ADD_MEMBER_FORM,
  AMEND_MODALS.AMEND_PARTY_LOADING,
  AMEND_MODALS.AMEND_PARTY_SUCCESS,
  AMEND_MODALS.AMEND_PARTY_FAILURE,
  AMEND_MODALS.AMEND_PARTY_MAX_OCCUPANCY,
  AMEND_MODALS.AMEND_PARTY_REMOVE_MEMBER,
];

export const AMEND_CONTACT_INFO_MODALS = [
  AMEND_MODALS.AMEND_LEAD_EMAIL_FORM,
  AMEND_MODALS.AMEND_LEAD_MOBILE_FORM,
];

export const getModalProps = (modalKey) => {
  switch (modalKey) {
    case AMEND_MODALS.AMEND_OUTFIT:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditOutfit__Confirm',
        descriptionDictionary: 'AvailabilityAmend__Modal__EditOutfit__Confirm',
        primaryActionDictionary: 'AvailabilityAmend__Modal__No',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Yes',
      };
    case AMEND_MODALS.AMEND_OUTFIT_FORM:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditOutfit',
        primaryActionDictionary: 'AvailabilityAmend__Modal__UpdateDetails',
      };
    case AMEND_MODALS.AMEND_OUTFIT_LOADING:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditOutfit__Loading',
      };
    case AMEND_MODALS.AMEND_OUTFIT_SUCCESS:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditOutfit__Success',
        descriptionDictionary: 'AvailabilityAmend__Modal__EditOutfit__Success',
        primaryActionDictionary:
          'AvailabilityAmend__Modal__EditOutfit__MyBookings',
        secondaryActionDictionary:
          'AvailabilityAmend__Modal__EditOutfit__MakeChanges',
        icon: svgCheck,
      };
    case AMEND_MODALS.AMEND_OUTFIT_FAILURE:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditOutfit__Fail',
        descriptionDictionary: 'AvailabilityAmend__Modal__EditOutfit__Fail',
        primaryActionDictionary: 'AvailabilityAmend__Modal__EditOutfit__Ok',
      };
    case AMEND_MODALS.AMEND_PARTY:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditParty__Confirm',
        descriptionDictionary: 'AvailabilityAmend__Modal__EditParty__Confirm',
        primaryActionDictionary: 'AvailabilityAmend__Modal__No',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Yes',
      };
    case AMEND_MODALS.AMEND_PARTY_FORM:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditParty',
        primaryActionDictionary: 'AvailabilityAmend__Modal__UpdateParty',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__AddPassenger',
      };
    case AMEND_MODALS.AMEND_PARTY_LEAD_FORM:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditLeadPassenger',
        primaryActionDictionary: 'AvailabilityAmend__Modal__UpdateDetails',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Back',
      };
    case AMEND_MODALS.AMEND_PARTY_MEMBER_FORM:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditPassenger',
        primaryActionDictionary: 'AvailabilityAmend__Modal__EditPassenger',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Cancel',
      };
    case AMEND_MODALS.AMEND_PARTY_ADD_MEMBER_FORM:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__AddPassenger',
        primaryActionDictionary: 'AvailabilityAmend__Modal__AddPassenger',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Cancel',
      };
    case AMEND_MODALS.AMEND_CANCEL:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__ConfirmExit',
        descriptionDictionary: 'AvailabilityAmend__Modal__ConfirmExit',
        primaryActionDictionary: 'AvailabilityAmend__Modal__Leave',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Close',
        iconColor: theme.COLOR_BERRY_RED_DARK,
        titleColor: theme.COLOR_AMBER_ORANGE,
        actionsDirection: 'row',
        actionsReversePlacement: true,
      };
    case AMEND_MODALS.AMEND_PARTY_MAX_OCCUPANCY:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__MaxOccupancy',
        descriptionDictionary: 'AvailabilityAmend__Modal__MaxOccupancy',
        primaryActionDictionary: 'AvailabilityAmend__Modal__Back',
      };
    case AMEND_MODALS.AMEND_PARTY_REMOVE_MEMBER:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__DeleteGuests',
        descriptionDictionary: 'AvailabilityAmend__Modal__DeleteGuests',
        primaryActionDictionary: 'AvailabilityAmend__Modal__No',
        secondaryActionDictionary: 'AvailabilityAmend__Modal__Yes',
      };
    case AMEND_MODALS.AMEND_DATE_OVERLAP:
      return {
        titleDictionary: 'AvailabilityAmend__Modal__EditDates__OverlapWarning',
        primaryActionDictionary: 'AvailabilityAmend__Modal__EditDates__Ok',
      };
    case AMEND_MODALS.AMEND_CANCEL_BOOKING:
      return {
        titleDictionary: 'Amend__Modal__CancelBooking',
        descriptionDictionary: 'Amend__Modal__CancelBooking',
        primaryActionDictionary: 'Amend__Modal__CancellationReason__Confirm',
        secondaryActionDictionary: 'Amend__Modal__CancellationReason__Cancel',
      };
    case AMEND_MODALS.AMEND_CANCEL_STAY:
      return {
        titleDictionary: 'Amend__Modal__CancelStay',
        descriptionDictionary: 'Amend__Modal__CancelStay',
        primaryActionDictionary: 'Amend__Modal__CancellationReason__Confirm',
        secondaryActionDictionary: 'Amend__Modal__CancellationReason__Cancel',
      };
    case AMEND_MODALS.AMEND_CANCEL_AMENDING:
      return {
        titleDictionary: 'Amend__NotAllowedCancel',
        descriptionDictionary: 'Amend__NotAllowedCancel__Amending__Description',
        primaryActionDictionary: 'AvailabilityAmend__Modal__Back',
      };
    case AMEND_MODALS.AMEND_LEAD_EMAIL_FORM:
      return {
        titleDictionary: 'Amend__Modal__EmailAddress',
        primaryActionDictionary: 'AvailabilityAmend__Modal__UpdateDetails',
      };
    case AMEND_MODALS.AMEND_LEAD_MOBILE_FORM:
      return {
        titleDictionary: 'Amend__Modal__MobileNumber',
        primaryActionDictionary: 'AvailabilityAmend__Modal__UpdateDetails',
      };
    case AMEND_MODALS.AMEND_REMOVE_MEMBERSHIP:
      return {
        titleDictionary: 'Amend__Modal__CancelMembership',
        primaryActionDictionary: 'Amend__Modal__CancelMembership',
      };
    case AMEND_MODALS.AMEND_NOT_ALLOWED:
      return {
        titleDictionary: 'Amend__NotAllowed',
      };
    default:
      return {};
  }
};

export const handleToggleModal = (client, popUp, type = '') => {
  client.writeData({
    data: {
      [popUp]: {
        open: !!type,
        type,
        __typename: popUp[0].toUpperCase() + popUp.slice(1),
      },
    },
  });
};

export const getModalActions = (client, type) => {
  const defaultActions = {
    onClose: () => handleToggleModal(client, 'noticePopUp'),
    onPrimaryAction: () => handleToggleModal(client, 'noticePopUp'),
    onSecondaryAction: () => handleToggleModal(client, 'noticePopUp'),
  };
  if (type === AMEND_MODALS.AMEND_OUTFIT) {
    return {
      ...defaultActions,
      onSecondaryAction: () => {
        handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_OUTFIT_FORM);
        handleToggleModal(client, 'noticePopUp');
      },
    };
  }
  return defaultActions;
};

export const cleanUpOutfitPayload = (outfit = {}) => {
  const {
    externalId, id, __typename, ...outfitPayload
  } = outfit;
  return outfitPayload;
};

export const populateQuoteFromBooking = (quote, booking) => {
  if (
    !booking?.campsiteBookings?.length ||
    (!quote?.bookingReference && !quote?.quoteId)
  ) {
    return quote;
  }
  const quoteCopy = { ...quote };
  if (!quoteCopy?.partyMembers?.length) {
    quoteCopy.partyMembers = booking.partyMembers;
  }
  if (!quoteCopy.outfit?.vehicleType) {
    quoteCopy.outfit = booking.outfit;
  }
  if (quoteCopy.departureDate === '0001-01-01') {
    quoteCopy.departureDate = booking.departureDate;
  }
  if (!quoteCopy.extras?.length && booking.extras.length) {
    quoteCopy.extras = booking.extras;
  }
  // Splits out campsite bookings for display in checkout
  booking.campsiteBookings.forEach((bookingItem) => {
    if (
      !quoteCopy.campsiteBookings?.some(
        (quoteBooking) => quoteBooking.id === bookingItem.id,
      )
    ) {
      quoteCopy.campsiteBookings.push(bookingItem);
    }
  });
  // Removes any duplicate pitches so we don't count bookings twice
  quoteCopy.campsiteBookings = quoteCopy.campsiteBookings.map((bookingItem) => {
    // Only return pitches which have a campsite ID matching the parent booking object
    const siteWithDuplicatePitchesRemoved = {
      ...bookingItem,
      pitches: bookingItem.pitches.filter((pitch) => pitch.id === bookingItem.id),
    };
    return siteWithDuplicatePitchesRemoved;
  });
  return quoteCopy;
};

export const computeAmendBalances = (booking, pitchIds, cancellationFee) => {
  const pitches = booking.campsiteBookings.reduce(
    (prev, cur) => [...prev, ...cur.pitches],
    [],
  );
  const cancellingAll = pitchIds.length === pitches.length;
  const balancePaid = booking.totalPrice - booking.outstandingBalance;
  let newTotal = booking.totalPrice;
  if (cancellingAll) {
    newTotal = 0;
  } else {
    const computedTotal = pitches.reduce((prev, cur) => {
      if (pitchIds.includes(Number(cur.id))) {
        return prev - cur.totalPrice;
      }
      return prev;
    }, newTotal);
    newTotal = computedTotal <= 0 ? 0 : computedTotal;
  }
  const balanceDueTotal = newTotal - balancePaid;
  const balanceDue = balanceDueTotal <= 0 ? 0 : balanceDueTotal;
  const needsPayment = balancePaid < cancellationFee;
  const totalRefund = balancePaid - cancellationFee - newTotal;
  const refund = totalRefund <= 0 ? 0 : totalRefund;
  return {
    previousTotal: booking.totalPrice,
    balancePaid,
    cancellations: newTotal - booking.totalPrice,
    newTotal,
    balanceDue,
    cancellationFee,
    needsPayment,
    refund,
  };
};

const uppercaseFirstLetter = (str) => str
  .split(' ')
  .map(
    (strItem) => strItem.charAt(0).toUpperCase() + strItem.slice(1).toLowerCase(),
  )
  .join(' ');

export const allowRemoveExtra = (isAmend, configuration, extra) => {
  if (!isAmend) {
    return true;
  }
  if (
    (isAmend || extra.isBookingBasket) &&
    extra.type === EXTRA_TYPES.SITE_NIGHT_VOUCHER
  ) {
    return false;
  }
  if (
    !configuration?.amendAndCancelExtraRestrictions
      ?.preventRemovingFromBookingNames ||
    !configuration?.amendAndCancelExtraRestrictions
      ?.preventRemovingFromBookingTypes
  ) {
    return false;
  }
  const {
    preventRemovingFromBookingNames,
    preventRemovingFromBookingTypes,
    preventBookingAmendOrCancelNames,
    preventBookingAmendOrCancelTypes,
  } = configuration.amendAndCancelExtraRestrictions;
  const name = uppercaseFirstLetter(extra.name || '');
  const type = uppercaseFirstLetter(extra.type || '');
  const casedPreventRemovingFromBookingNames =
    preventRemovingFromBookingNames.map(uppercaseFirstLetter);
  const casedPreventRemovingFromBookingTypes =
    preventRemovingFromBookingTypes.map(uppercaseFirstLetter);
  const casedPreventBookingAmendOrCancelNames =
    preventBookingAmendOrCancelNames.map(uppercaseFirstLetter);
  const casedPreventBookingAmendOrCancelTypes =
    preventBookingAmendOrCancelTypes.map(uppercaseFirstLetter);

  return (
    !casedPreventRemovingFromBookingNames.includes(name) &&
    !casedPreventRemovingFromBookingTypes.includes(type) &&
    !casedPreventBookingAmendOrCancelNames.includes(name) &&
    !casedPreventBookingAmendOrCancelTypes.includes(type)
  );
};

export const quoteHasPitchStartingSoon = (quote) => {
  const bookings = quote?.campsiteBookings;
  let result = false;
  if (!bookings?.length) {
    return result;
  }
  bookings.forEach((booking) => {
    booking.pitches.forEach((pitch) => {
      if (
        pitch.amendmentRestrictions?.includes(
          AMEND_RESTRICTIONS.PITCH_STARTING_SOON,
        )
      ) {
        result = true;
      }
    });
  });
  return result;
};

export const getCancellationExtraCost = (quote) => {
  if (
    !quote?.campsiteBookings?.length ||
    !quote?.isBookingAmendmentQuote ||
    !quote?.extras?.length
  ) {
    return 0;
  }
  return quote.extras.reduce((prev, cur) => {
    if (cur.type === 'CANCELLATION CHARGE') {
      return prev + cur.totalPrice;
    }
    return prev;
  }, 0);
};

const getCampsiteBreakdown = (quote) => quote.campsiteBookings.reduce(
  (prev, cur) => {
    const pitch = cur.pitches?.[0];
    if (!pitch) {
      return prev;
    }
    const pitchPrice = pitch.totalPrice ?? 0;
    let { cancellationTotal } = prev;
    if (pitch.cancelReasonId && pitch.cancellationFee) {
      cancellationTotal += pitch.cancellationFee;
    }
    return { ...prev, total: prev.total + pitchPrice, cancellationTotal };
  },
  { total: 0, cancellationTotal: 0 },
);

export const getAmendmentBreakdown = (quote) => {
  if (!quote?.campsiteBookings?.length || !quote?.isBookingAmendmentQuote) {
    return null;
  }
  const campsiteBreakdown = getCampsiteBreakdown(quote);
  const cancellationExtras = getCancellationExtraCost(quote);

  if (!quote.totalPrice) {
    return {
      total:
        campsiteBreakdown.total +
        campsiteBreakdown.cancellationTotal +
        cancellationExtras,
      cancellationTotal: campsiteBreakdown.cancellationTotal,
    };
  }
  return {
    total: quote.totalPrice,
    cancellationTotal: campsiteBreakdown.cancellationTotal,
  };
};

const getPitchTotal = (campsiteBooking) => {
  const { pitches } = campsiteBooking;
  const totalPitchCost = pitches
    .map((pitch) => pitch.totalPrice)
    .reduce((acc, curr) => acc + curr);
  return totalPitchCost;
};

const getPitchCancellationFees = (campsiteBooking) => {
  const { pitches } = campsiteBooking;
  const pitchCancellations = pitches
    .map((pitch) => pitch.cancellationFee)
    .reduce((acc, curr) => acc + curr);
  return pitchCancellations;
};

// Return campsite prices and basic info (name)
export const getComponentBreakdown = (quote) => {
  if (!quote) return null;

  const campsiteTotalBreakdown = quote.campsiteBookings.map((booking) => {
    const total = getPitchTotal(booking);
    return {
      ...booking.pitches[0],
      price: total,
      info: booking.campsite.name,
    };
  });

  const cancellationTotal = quote.campsiteBookings.map((booking) => {
    const fees = getPitchCancellationFees(booking);
    return {
      ...booking.pitches[0],
      price: fees,
      info: booking.campsite.name,
    };
  });

  return {
    campsiteTotalBreakdown: campsiteTotalBreakdown.sort(sortBookings),
    cancellationTotal,
  };
};
