import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { Icon } from '../ui';
import {
  ComponentTable,
  ComponentTableRow,
  ComponentTableData,
  ComponentTableHeader,
  IconContainer,
  ModalClose,
  WarmModal,
  ModalBodyContent,
  ModalTitle,
  BasketModalContent,
} from './BasketModal.style';
import { formatPriceWithCurrency } from '../../lib/format';

import IbePropTypes from '../../IbePropTypes';
import { getDictionaryItem } from '../../hocs/withDictionary';

import svgError from '../../static/images/icons/Error.svg';
import svgClose from '../../static/images/icons/Close.svg';

import theme from '../../styles/config/theme';

const BasketModal = ({
  active,
  closeModal,
  children,
  labels,
  tableData,
  client,
}) => {
  const stayInfoLabel = getDictionaryItem(
    client,
    'AvailabilityDetails__PriceSummary__RetainedDeposit__StayInformation__Text',
  );
  const stayLabel = getDictionaryItem(
    client,
    'AvailabilityDetails__PriceSummary__RetainedDeposit__Stay__Text',
  );
  return (
    <WarmModal active={active} onClickOutside={closeModal} overlay>
      <BasketModalContent size="small">
        <ModalClose onClick={closeModal}>
          <Icon icon={svgClose} color={theme.COLOR_WHITE} />
        </ModalClose>
        <IconContainer>
          <Icon
            icon={svgError}
            style={{ fontSize: '4rem' }}
            color={theme.COLOR_CYAN_DARK}
          />
        </IconContainer>
        <ModalTitle>{getDictionaryItem(client, labels?.modalTitle)}</ModalTitle>
        <ModalBodyContent>{children}</ModalBodyContent>
        <ComponentTable>
          <ComponentTableRow>
            <ComponentTableHeader width="50%">
              {getDictionaryItem(client, labels?.staysTitle)}
            </ComponentTableHeader>
            <ComponentTableHeader>{stayInfoLabel}</ComponentTableHeader>
          </ComponentTableRow>
          {tableData.map((datum, index) => (
            <ComponentTableRow>
              <ComponentTableData>
                {formatPriceWithCurrency(datum.price)}
              </ComponentTableData>
              <ComponentTableData>
                {stayLabel} {index + 1} - {datum.info}
              </ComponentTableData>
            </ComponentTableRow>
          ))}
        </ComponentTable>
      </BasketModalContent>
    </WarmModal>
  );
};

BasketModal.propTypes = {
  active: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  labels: PropTypes.shape({
    modalTitle: PropTypes.string,
    staysTitle: PropTypes.string,
  }).isRequired,
  tableData: PropTypes.arrayOf({
    price: PropTypes.number,
    info: PropTypes.string,
  }).isRequired,
};

BasketModal.defaultProps = {
  active: false,
};

export default withApollo(BasketModal);
