import moment from 'moment';
import diffDays from 'date-fns/difference_in_calendar_days';
import {
  DISALLOWED_STATUSES,
  RENEWAL_STATUSES,
  ALLOWED_STATUSES,
} from '../../config/membershipStatus';
import { DATE_FORMAT_INPUT } from '../../config/locale';

export const getBookingsFromData = (data) => {
  let bookings = [];
  if (data && data.currentuserbookings && data.currentuserbookings.bookings) {
    bookings = data.currentuserbookings.bookings;
  }

  return bookings;
};

export const getBookingFromData = (data) => {
  let booking;
  if (
    data
    && data.getcurrentuserbookingdetails
    && data.getcurrentuserbookingdetails.bookingDetails
  ) {
    booking = data.getcurrentuserbookingdetails.bookingDetails;
  }

  return booking;
};

export const isPreviousBooking = (booking) => (
  diffDays(new Date(booking.departureDate), new Date()) < 0
);

// A booking goes from upcoming to previous the day after
// it's return date (as per Alex's comment)
export const isUpcomingBooking = (booking) => {
  const returnDate = new Date(booking.returnDate);
  const dayAfterBookingEnds = returnDate.setDate(returnDate.getDate() + 1);
  return diffDays(dayAfterBookingEnds, new Date()) >= 0;
};

export const getPreviousBookings = (bookings = []) => (
  bookings.filter(isPreviousBooking)
);

export const getUpcomingBookings = (bookings = []) => (
  bookings.filter(isUpcomingBooking)
);

export const hasOutstandingBalance = (booking) => (
  parseFloat(booking.outstandingBalance, 2) > 0
);

export const getBookingWithPaymentDue = (bookings = []) => {
  const orderedBalanceDue = bookings.filter(hasOutstandingBalance);

  if (orderedBalanceDue.length) {
    return orderedBalanceDue[orderedBalanceDue.length - 1];
  }

  return undefined;
};

export const bookingPaymentsAllowed = (booking, membershipStatus) => {
  const membershipDisallowed = DISALLOWED_STATUSES.includes(membershipStatus);
  const membershipRenewal = RENEWAL_STATUSES.includes(membershipStatus);
  if (
    ALLOWED_STATUSES.includes(membershipStatus) ||
    (!membershipRenewal && !booking?.hasMemberOnlyProducts)
  ) {
    return true;
  }
  if (membershipDisallowed || membershipRenewal) {
    return false;
  }
  return false;
};

export const showPayBalanceButton = (booking = {}) => hasOutstandingBalance(booking) > 0
  && isUpcomingBooking(booking);

const mapPortInfo = (ports, crossing) => {
  if (!crossing) return crossing;
  const { inboundItinerary, outboundItinerary } = crossing;

  const inboundDeparturePort =
    ports.find((port) => port.portCodes.includes(inboundItinerary?.departurePort));
  const outboundDeparturePort =
    ports.find((port) => port.portCodes.includes(outboundItinerary?.departurePort));
  const inboundArrivalPort =
    ports.find((port) => port.portCodes.includes(inboundItinerary?.arrivalPort));
  const outboundArrivalPort =
    ports.find((port) => port.portCodes.includes(outboundItinerary?.arrivalPort));

  return {
    ...crossing,
    ...((inboundDeparturePort || inboundArrivalPort) && {
      inboundItinerary: {
        ...inboundItinerary,
        departurePort: inboundDeparturePort,
        arrivalPort: inboundArrivalPort,
      },
    }),
    ...((outboundDeparturePort || outboundArrivalPort) && {
      outboundItinerary: {
        ...outboundItinerary,
        departurePort: outboundDeparturePort,
        arrivalPort: outboundArrivalPort,
      },
    }),
  };
};

const mapSupplierInfo = (suppliers, crossing) => {
  if (!crossing) return crossing;
  const { inboundItinerary, outboundItinerary } = crossing;
  const inboundSupplier =
    suppliers.find((supplier) => supplier.supplierCodes.includes(inboundItinerary?.supplierCode));
  const outboundSupplier =
    suppliers.find((supplier) => supplier.supplierCodes.includes(outboundItinerary?.supplierCode));
  return {
    ...crossing,
    ...(inboundSupplier && {
      inboundItinerary: {
        ...inboundItinerary,
        supplier: inboundSupplier,
      },
    }),
    ...(outboundSupplier && {
      outboundItinerary: {
        ...outboundItinerary,
        supplier: outboundSupplier,
      },
    }),
  };
};

export const parseCrossings = (bookings, ports, suppliers) => bookings.map((booking) => ({
  ...booking,
  crossingBookings: booking.crossingBookings.map((crossing) => {
    const crossingWithPortInfo = mapPortInfo(ports, crossing);
    const crossingWithSupplierInfo = mapSupplierInfo(suppliers, crossingWithPortInfo);
    return crossingWithSupplierInfo;
  }),
}));

/**
 * Determines the message to display based on the booking conditions.
 *
 * @param {boolean} isOverseas - Indicates if the booking is overseas.
 * @param {string} penaltyDate - The penalty date in string format.
 * @param {Function} getDictionaryItem - The function to fetch dictionary items.
 * @returns {object} - An object containing the message and link from the dictionary.
 */
export function getBasketItemImportantInfoMessage(
  isOverseas, penaltyDate, getDictionaryItem, client,
) {
  const linkResult = getDictionaryItem(client, 'ManageMyBookings__BasketItemImportantInfo__Link__Title');

  // '.' indicates on the link title indicates the client has turned the link feature off
  // and so don't show value
  const linkTitleValue = linkResult === '.'
    ? undefined
    : linkResult;

  if (isOverseas) {
    return {
      message: getDictionaryItem(client, 'ManageMyBookings__BasketItemImportantInfo__IsOverseasBooking__RichText'),
      linkTitle: linkTitleValue,
    };
  }

  const isPenaltyDateValid = moment(penaltyDate).isValid();
  if (!isPenaltyDateValid) {
    return {
      message: getDictionaryItem(client, 'ManageMyBookings__BasketItemImportantInfo__PenaltyDateIssue__RichText'),
      linkTitle: undefined,
    };
  }

  const formattedPenaltyDate = moment(penaltyDate).format(DATE_FORMAT_INPUT);
  const message = getDictionaryItem(client, 'ManageMyBookings__BasketItemImportantInfo__IsUK__RichText')
    .replace('{{penaltyDate}}', formattedPenaltyDate);

  return { message, linkTitle: linkTitleValue };
}
