import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import BookingSummaryItemStyled, {
  Label, Value, Row, Wrapper,
} from './BookingSummaryItem.style';

function BookingSummaryItem({
  label, value, flex, className, noMargin, reviewItem,
}) {
  if (!value) return null;
  const values = Array.isArray(value) ? value : [value];
  const filteredValues = values.filter(({ name }) => name != null);

  return (
    <BookingSummaryItemStyled
      className={className}
      noMargin={noMargin}
      reviewItem={reviewItem}
    >
      <Label reviewItem={reviewItem} flex={flex}>{label}{label ? ':' : ''}</Label>
      <Wrapper>
        {!filteredValues.length &&
          <Value>
            <b data-testid="none-text">None</b>
          </Value>
        }

        {!!filteredValues.length && filteredValues
          .sort((a, b) => a.name?.localeCompare(b.name))
          .map(v => (
            <Row key={uuid()}>
              <Value reviewItem={reviewItem}>
                <b data-testid="name-text" key={v}>{v.name}</b>
                {v.description && <span>{v.description}</span>}
              </Value>
            </Row>
          ))}
      </Wrapper>
    </BookingSummaryItemStyled>
  );
}

BookingSummaryItem.propTypes = {
  flex: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  noMargin: PropTypes.bool,
  reviewItem: PropTypes.bool,
};

BookingSummaryItem.defaultProps = {
  className: '',
  flex: '',
  label: '',
  value: null,
  noMargin: false,
  reviewItem: false,
};

export default BookingSummaryItem;
